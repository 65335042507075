<template>
  <v-app class="copilot">
    <div class="logged-out-content">
      <div class="logo">
        <icon-logo_copilot style="font-size: 200px; height: 70px" filled />
        <span class="logo__span font-size-12">VOTRE ESPACE CONDUCTEUR</span>
      </div>
      <v-main style="width: 100%">
        <slot />
      </v-main>
      <img class="logo_image" alt="logo fatec" />
    </div>
    <div v-if="loaderStore.isLoading()" class="progress-layout d-flex align-center justify-center">
      <v-progress-circular color="var(--theme-icone)" indeterminate />
    </div>
  </v-app>
</template>

<script lang="ts" setup>
const authentificationStore = useAuthentificationStore();
const loaderStore = useLoaderStore();
const route = useRoute();

const redirect = function () {
  if (authentificationStore.isAuthenticated() && route.path === '/login') {
    //navigateTo('/');
  }
};

// Created
redirect();
</script>
<style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
}

.copilot {
  /* background-color: var(--theme-identite); */
  background: linear-gradient(180deg, var(--theme-identite) 60%, rgba(255, 255, 255, 1) 60%);
}

.logged-out-content {
  height: 100%;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: var(--white);
}

.logo__span {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-stretch: ultra-expanded;
  margin-top: 10px;
}

.logo_image {
  margin: 24px auto auto auto;
  width: 40%;
  max-width: 200px;
  height: 96px;
  content: var(--theme-logo);
  object-fit: contain;
}

@media screen and (max-height: 700px) {
  .logo svg {
    width: 150px;
    transition: 0.2s all cubic-bezier(0.4, 0, 0.6, 1);
  }

  .logo__span {
    margin-top: 0;
    font-size: 11px;
  }

  .logo_image {
    margin-top: 0;
  }
}
</style>
